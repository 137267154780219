import React from "react";
import "../styles/Landing.css";
import logo from "../Assets/imgs/logo.webp";
import mainImg from "../Assets/imgs/main-img.webp";
import gamenow from "../Assets/imgs/game-now.webp";

type Props = {
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNo: string;
  handleOnPressOTPBtn: () => void;
  errorMsg: string;
  showLoader: boolean;
};
const LandingPage = (props: Props) => {
  return (
    <>
      <div className="game-zone">
        <div className="main-content">
          <div className="logo">
            <img src={logo} width={140} height={140} alt="logo" />
          </div>
          <div className="main-img">
            <img
              src={mainImg}
              width={370}
              height={330}
              alt="main"
              className="mainIMG"
            />
            <img
              src={gamenow}
              width={870}
              height={170}
              alt="game now"
              className="game-now"
            />
          </div>
          <div className="subscribe-box">
            <div className="free-trial-text">1 Day Free</div>
            <div className="subscribe-text">
              <p className="get-access">Subscribe Now Get Access To </p>
              <span className="jgz">JAZZ GAME ZONE</span>
              {/* <p className="getotp-text">Please input number to get OTP</p> */}
            </div>
            {/* <div className="input-wraper">
              <span>+92</span>
              <input type="text" placeholder="3xx-xxxx-xxx" />
            </div> */}
            {props.errorMsg ? (
              <>
                <p className="error">{props.errorMsg}</p>
              </>
            ) : (
              <></>
            )}
            {props.showLoader ? (
              <div className="send-otp">
                <button>Loading...</button>
              </div>
            ) : (
              <div className="send-otp">
                <button
                  onClick={() => {
                    props.handleOnPressOTPBtn();
                  }}
                >
                  Send OTP
                </button>
              </div>
            )}
            <p className="service-charges">
              Service cost: 10 PKR / daily (Inc tax).
            </p>
            <div className="terms-condition">
              <span>
                <a href="http://apps.gamenow.com.pk/sdp/terms" target="_blank">
                  Terms & Conditions
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
