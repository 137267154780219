import React from "react";
import "../styles/Awareness.css";
import gamezoneawrmain from "../Assets/imgs/gamezone-awr-main.webp";
type Props = {};
const Awareness = (props: Props) => {
  return (
    <div className="awareness-page">
      <div className="image">
        <img
          src={gamezoneawrmain}
          width={340}
          height={598}
          alt="awareness main image"
        />
      </div>
    </div>
  );
};

export default Awareness;
