import React, { useState, useEffect } from "react";
import "../styles/Landing.css";
import logo from "../Assets/imgs/logo.webp";
import mainImg from "../Assets/imgs/main-img.webp";
import gamenow from "../Assets/imgs/game-now.webp";
type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  inputRef: React.RefObject<HTMLInputElement>[];
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  error: string;
  showError: boolean;
  handleSubmitBtn: () => void;
  showLoader: boolean;
  timer: number;
  onResendOtpPressed: () => void;
};
const OTPPage = (props: Props) => {
  return (
    <>
      <div className="game-zone">
        <div className="main-content">
          <div className="logo">
            <img src={logo} width={140} height={140} alt="logo" />
          </div>
          <div className="main-img">
            <img
              src={mainImg}
              width={370}
              height={330}
              alt="main"
              className="mainIMG"
            />
            <img
              src={gamenow}
              width={870}
              height={170}
              alt="game now"
              className="game-now"
            />
          </div>
          <div className="subscribe-box">
            <div className="free-trial-text">1 Day Free</div>
            <div className="subscribe-text">
              <p className="get-access">Subscribe Now Get Access To </p>
              <span className="jgz">JAZZ GAME ZONE</span>
              <p className="getotp-text">Please enter OTP received via SMS </p>
            </div>
            <div className="input-wraper otp">
              <input
                type="tel"
                name="one"
                maxLength={1}
                autoFocus
                value={props.otp.one}
                onChange={(e) => {
                  props.handleChangeOTP(e, 0);
                }}
                ref={props.inputRef[0]}
                onKeyDown={(e) => {
                  props.handleBackspace(e, 0);
                }}
              />
              <input
                type="tel"
                name="two"
                maxLength={1}
                value={props.otp.two}
                onChange={(e) => {
                  props.handleChangeOTP(e, 1);
                }}
                ref={props.inputRef[1]}
                onKeyDown={(e) => {
                  props.handleBackspace(e, 1);
                }}
              />
              <input
                type="tel"
                name="three"
                maxLength={1}
                value={props.otp.three}
                onChange={(e) => {
                  props.handleChangeOTP(e, 2);
                }}
                ref={props.inputRef[2]}
                onKeyDown={(e) => {
                  props.handleBackspace(e, 2);
                }}
              />
              <input
                type="tel"
                name="four"
                maxLength={1}
                value={props.otp.four}
                onChange={(e) => {
                  props.handleChangeOTP(e, 3);
                }}
                ref={props.inputRef[3]}
                onKeyDown={(e) => {
                  props.handleBackspace(e, 3);
                }}
              />
            </div>
            {props.showError ? (
              <>
                <p className="invalid">{props.error}</p>
              </>
            ) : (
              <></>
            )}
            <div className="resendotp">
              {props.timer <= 0 ? (
                <p>
                  Didn’t Receive OTP?{" "}
                  <span onClick={props.onResendOtpPressed}>Resend</span>
                </p>
              ) : (
                <p>Resend OTP in 00:{props.timer}</p>
              )}
            </div>

            {props.showLoader ? (
              <div className="send-otp">
                <button>Loading...</button>
              </div>
            ) : (
              <div className="send-otp">
                <button
                  onClick={() => {
                    props.handleSubmitBtn();
                  }}
                >
                  Subscribe
                </button>
              </div>
            )}
            <p className="service-charges">
              Service cost: 10 PKR / daily (Inc tax).
            </p>
            <div className="terms-condition">
              <span>
                <a href="http://apps.gamenow.com.pk/sdp/terms" target="_blank">
                  Terms & Conditions
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTPPage;
